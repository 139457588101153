import { Singleton, Resources } from "../Resource";
class Service extends Resources {
  route = "form";
  routes = {
    find: "",
    create: "/create",
    show: "/show",
    update: "/update",
    delete: "/delete",
  };
  
  ranking = {};

  constructor() {
    super(arguments);
  }

}

export default Singleton(Service);
